<template>
    <button @click="goToAdminMode">Modify</button>
  </template>
  
  <script>
  export default {
    props: {
      // Ensure that router is passed as a prop
      router: Object
    },
    methods: {
      goToAdminMode() {
        // Check if router is defined before accessing it
        if (this.router) {
          // Use this.$router instead of this.router.push
          this.$router.push({ name: 'admin' });
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add your button styling here */
  </style>
  