<template>
  <div id="app">
    <FormComponent @new-entry="addEntry"/>
    <EntriesListComponent :entries="entries" :isAdminMode="isAdminMode" @delete-entry="deleteEntry" @sort-by="handleSortBy"/>
    <router-view></router-view>
    <ModifyButton :router="$router" v-if="isAdminMode" @logout="logoutAdmin"/>
    <div class="LoginButton" v-if="!isAdminMode">
      <label>Password:</label>
      <input type="password" v-model="password">
      <button @click="loginAdmin">Login</button>
    </div>
  </div>
</template>

<script>
import FormComponent from './components/FormComponent.vue';
import EntriesListComponent from './components/EntriesListComponent.vue';
import ModifyButton from '@/components/ModifyButton.vue';
import { ADMIN_PASSWORD } from './adminPassword';

export default {
  components: {
    FormComponent,
    EntriesListComponent,
    ModifyButton
  },
  data() {
    return {
      entries: [],
      isAdminMode: false,
      sortOrder: 'submissionDate',
      password: ''
    };
  },
  methods: {
    addEntry(newEntry) {
      this.entries.push(newEntry);
      this.checkExpiredEntries();
    },
    deleteEntry(id) {
      this.entries = this.entries.filter(entry => entry.id !== id);
    },
    checkExpiredEntries() {
      const now = new Date();
      this.entries = this.entries.filter(entry => {
        const expirationDate = new Date(entry.expiration);
        return expirationDate > now;
      });
    },
    handleSortBy(field) {
      this.sortOrder = field;
    },
    loginAdmin() {
      if (this.password === ADMIN_PASSWORD) {
        this.isAdminMode = true;
        this.password = ''; // Clear password field after login
      } else {
        alert('Incorrect password');
      }
    },
    logoutAdmin() {
      this.isAdminMode = false;
    }
  }
};
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.LoginButton {
  position: absolute;
  top: 2rem;
  right: 4rem;
}
</style>
