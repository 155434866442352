<template>
  <div class="form-container">
    <h1>Open Calls</h1>
    <form @submit.prevent="submitForm" class="form">
      <div class="form-group">
        <label for="name">Name of the Open Call:</label>
        <input type="text" id="name" v-model="name" required>
      </div>
      <div class="form-group" :class="{ 'error': submitted && !isValidURL }">
        <label for="link">Link to Open Call:</label>
        <input type="url" id="link" v-model="link" required :class="{ 'invalid': submitted && !isValidURL }" @keydown.enter="submitForm">
        <span v-if="submitted && !isValidURL" class="error">Please enter a valid URL.</span>
      </div>
      <div class="form-group">
        <label for="expiration">Deadline:</label>
        <input type="date" id="expiration" v-model="expiration" required @keydown.enter="submitForm">
      </div>
      <button type="submit">Submit</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      link: '',
      expiration: '',
      isValidURL: true,
      submitted: false // Flag to track form submission
    };
  },
  methods: {
    submitForm() {
      // Set submitted flag to true
      this.submitted = true;

      // Validate URL before submitting
      this.isValidURL = this.validateURL(this.link);
      if (!this.isValidURL) return;

      // Check if expiration date is before today
      const today = new Date();
      const selectedDate = new Date(this.expiration);
      if (selectedDate < today) {
        alert('Expiration date cannot be in the past!');
        return;
      }

      const newEntry = {
        name: this.name,
        link: this.link,
        expiration: this.expiration,
        submissionDate: new Date().toISOString()
      };
      this.$emit('new-entry', newEntry);
      this.name = '';
      this.link = '';
      this.expiration = '';
    },
    validateURL(url) {
      // Simple URL validation using regular expression
      const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
      return pattern.test(url);
    }
  }
};
</script>


<style scoped>
.form-container {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="date"],
input[type="url"],
button {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.invalid {
  border-color: red;
}

button {
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  margin-top: 5px; /* Add margin to separate from input field */
  display: block; /* Display the error message as a block element */
}
.error input[type="url"] {
  border-color: red;
}
</style>
