<template>
  <div class="entries-list-container">
    <h2>Entries List</h2>
    <div class="button-container">
      <button @click="sortBy('submissionDate')">Sort by Submission Date</button>
      <button @click="sortBy('expiration')">Sort by Expiration Date</button>
    </div>
    <ul class="entries-list">
      <li v-for="entry in filteredEntries" :key="entry.id" class="entry-item">
        <a :href="entry.link" target="_blank" class="entry-headline">{{ entry.name }}</a>
        <div>
          <strong>Submission Date:</strong> {{ formatDate(entry.submissionDate) }}
        </div>
        <div>
          <strong>Expiration Date:</strong> {{ formatDate(entry.expiration) }}
        </div>
        <!-- Show delete button only in admin mode -->
        <button v-if="isAdminMode" @click="deleteEntry(entry.id)">Delete</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    entries: {
      type: Array,
      default: () => []
    },
    isAdminMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    filteredEntries() {
      const now = new Date();
      return this.entries.filter(entry => new Date(entry.expiration) > now);
    }
  },
  methods: {
    deleteEntry(id) {
      if (this.isAdminMode) {
        this.$emit('delete-entry', id);
      }
    },
    sortBy(field) {
      this.$emit('sort-by', field);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
    }
  }
};
</script>

<style scoped>
.entries-list-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.button-container {
  margin-bottom: 20px;
}

.entries-list {
  list-style: none;
  padding: 0;
}

.entry-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

.entry-headline {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  display: block;
}
</style>
